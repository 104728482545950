import React from 'react';
import NavigationBar from '../NavigationBar/NavigationBar';
import AboutMe from '../AboutMe/AboutMe';
import Socials from '../Socials/Socials';
import Footer from '../Footer/Footer';
import Projects from '../Projects/Projects';
import Career from '../Career/Career';
import Home from '../Home/Home';
import jobs from '../../data/jobs';

const App = () => {
	return (
		<>
			<main name='Home'>
				<NavigationBar />
				<Home />
			</main>
			<AboutMe />
			<Career data={jobs} />
			<Projects />
			<Socials />
			<Footer />
		</>
	);
};

export default App;
