import React from 'react';
import './ProjectCard.scss';
import Fade from 'react-reveal/Fade';

const ProjectCard = project => {
	const {
		title,
		description,
		technologies1,
		technologies2,
		repoLink,
		image,
		role
	} = project;
	return (
		<Fade duration={500}>
			<div className='ProjectCard'>
				<a href={repoLink} target='_blank' rel='noopener noreferrer'>
					<img src={image} alt={`Screenshot of ${title}`} />
				</a>
				<section className='card-text'>
					<h3>
						<a href={repoLink} target='_blank' rel='noopener noreferrer'>
							<span>{title}</span>{' '}
						</a>
					</h3>
					<p className='overlap'>
						<span>{role}</span> <br /> {description}
					</p>
					<section className='tech'>
						<ul>
							{technologies1.map((tech, index) => (
								<li key={index}>{tech}</li>
							))}
						</ul>
						<ul>
							{technologies2.map((tech, index) => (
								<li key={index}>{tech}</li>
							))}
						</ul>
					</section>
				</section>
			</div>
		</Fade>
	);
};

export default ProjectCard;
