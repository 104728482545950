const jobs = [
  {
    id: 0,
    title: "Frontend Engineer",
    company: "Atlassian",
    location: "Denver, CO | New York City, NY",
    dateRange: "Feb 2022 - Present",
    url: "https://atlassian.com",
    description: [
      "Work on Confluence, a  flagship Atlassian product used by millions of people every day.",
      "Plan and develop thoroughly with scalability and performance in mind.",
      "Implement new features, fix bugs, and improve performance",
      "Analyze markers to learn how users use our product so we can iterate and improve the experience."
    ],
  },
  {
    id: 1,
    title: "Senior Digital Analyst (SWE II)",
    company: "McKinsey & Company",
    location: "Denver, CO",
    dateRange: "Feb 2020 - Feb 2022",
    url: "https://mckinsey.com",
    description: [
      "Collaborated with a variety of clients from various industries identifying and resolving complex technical issues, and driving digital transformations leveraging an array of development frameworks and tools.",
      "Thorough and fast-paced full-stack development work every sprint on both small and large-scale applications, leveraging either Angular and/or React for the frontend and NodeJS, Graphql, or .NET for the backend throughout the past two years.",
      "Supported multiple workflow transformations from waterfall to agile, contributed to backlog refinement and planning sessions, as well as design jam sessions with clients.",
    ],
  },
  {
    id: 2,
    title: "Frontend Engineering Student",
    company: "Turing School",
    location: "Denver, CO",
    dateRange: "April 2019 - Jan 2020",
    url: "https://turing.io",
    description: [
      "Collaborated in fast-paced, cross-functional teams for more than 60 hours a week for 9 months building a variety of interesting and meaningful projects.",
      "Built robust, scalable, and fully-tested applications with mainly front-end tools and some back-end technologies.",
    ],
  },
];

export default jobs;
